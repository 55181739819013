.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.noHighlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.noselect {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
